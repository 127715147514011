import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  SCHEDULE_JOBS_API,
  UPDATE_SCHEDULED_JOB_API,
  DELETE_SCHEDULED_JOB_API,
} from '@constants/endpoints';

export const FETCH_ACCOUNTS_SCHEDULES = 'FETCH_ACCOUNTS_SCHEDULES';
export const UPDATE_SCHEDULES = 'UPDATE_SCHEDULES';
export const REMOVE_SCHEDULES = 'REMOVE_SCHEDULES';
export const REMOVE_SCHEDULE_ROW = 'REMOVE_SCHEDULE_ROW';

export const fetchAccountsScheduleAction = createActionRoutine(
    FETCH_ACCOUNTS_SCHEDULES,
    SCHEDULE_JOBS_API,
);

export const updateScheduleAction = createActionRoutine(UPDATE_SCHEDULES, UPDATE_SCHEDULED_JOB_API);
export const removeScheduleAction = createActionRoutine(REMOVE_SCHEDULES, DELETE_SCHEDULED_JOB_API);
export const removeScheduleRowAction = createActionRoutine(REMOVE_SCHEDULE_ROW);
