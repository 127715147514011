export const optionButtons = [
    { id: 1, label: "Reports" },
    { id: 2, label: "Projects" },
    { id: 3, label: "Contractor Companies" },
    { id: 4, label: "Associates" }
  ];
  
  export const reportOptions = [
    { id: 1, label: "Submissions Report" },
    { id: 2, label: "Corrective Actions Report" },
    { id: 3, label: "Pre Task Plans Report" },
    { id: 4, label: "Goformz Report" }
  ];

  export const projectOptions = [
    { id: 1, label: "All" },
    { id: 2, label: "Hospital1" },
    { id: 3, label: "ProjectA" },
    { id: 4, label: "ProjectB" },
    { id: 5, label: "Hospital" },
    { id: 6, label: "New Proj" }
  ];
  
  export const programOptions = [
    { id: 1, label: "All" },
    { id: 2, label: "SAFETY INSPECTIONS" },
    { id: 3, label: "INSPECT - EM-385 1-1" },
    { id: 4, label: "Safety Plans (ESP)" },
    { id: 5, label: "PERMITS" },
    { id: 6, label: "OBSERVATIONS" },
    { id: 7, label: "JSA-AHA 3" },
    { id: 8, label: "Test 123" },
  ];