import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, Input } from 'antd';

interface EmailModalProps {
  visible: boolean;
  emailList: string[];
  selectedEmails: string[];
  onClose: () => void;
  onSave: (emails: string[]) => void;
}

const EmailModal: React.FC<EmailModalProps> = ({
  visible,
  emailList,
  selectedEmails,
  onClose,
  onSave,
}) => {
  const [checkedEmails, setCheckedEmails] = useState<string[]>(selectedEmails);
  const [searchQuery, setSearchQuery] = useState('');

  // Update checked emails when selectedEmails change (from the parent component)
  useEffect(() => {
    setCheckedEmails(selectedEmails);
  }, [selectedEmails]);

  const filteredEmails = emailList.filter((email) =>
    email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (email: string, checked: boolean) => {
    if (checked) {
      setCheckedEmails([...checkedEmails, email]);
    } else {
      setCheckedEmails(checkedEmails.filter((e) => e !== email));
    }
  };

  const handleSave = () => {
    onSave(checkedEmails);
    onClose();
  };

  return (
    <Modal
      title="Select Email(s)"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          disabled={checkedEmails.length === 0}
        >
          Select
        </Button>,
      ]}
      zIndex={1051}
      getContainer={false}
    >
      <Input
        type="text"
        placeholder="Search emails..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          marginBottom: '10px',
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
      />
      <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '8px 3px' }}>
        {filteredEmails.map((email) => (
          <div key={email} style={{ marginBottom: '8px' }}>
            <Checkbox
              checked={checkedEmails.includes(email)}
              onChange={(e) => handleCheckboxChange(email, e.target.checked)}
            >
              {email}
            </Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default EmailModal;
