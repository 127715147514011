import React, { useState, useEffect } from 'react';
import { Modal, Switch } from 'antd';
import TimeDropdown from './timedropdown';
import EmailModal from '@shared/floating-button/EmailModal';

const timezones = [
  { value: 'America/Chicago', label: 'Central Time (Texas)' },
  { value: 'America/New_York', label: 'Eastern Time (US)' },
  { value: 'America/Los_Angeles', label: 'Pacific Time (US)' },
  { value: 'America/Denver', label: 'Mountain Time (US)' },
  { value: 'America/Phoenix', label: 'Arizona (no DST)' },
  { value: 'America/Anchorage', label: 'Alaska Time' },
  { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time' },
  { value: 'America/Toronto', label: 'Eastern Time (Canada)' },
  { value: 'America/Vancouver', label: 'Pacific Time (Canada)' },
  { value: 'America/Edmonton', label: 'Mountain Time (Canada)' },
  { value: 'America/Winnipeg', label: 'Central Time (Canada)' },
  { value: 'America/St_Johns', label: 'Newfoundland Time (Canada)' },
  { value: 'America/Halifax', label: 'Atlantic Time (Canada)' },
  { value: 'America/Regina', label: 'Central Time (Canada - Saskatchewan)' },
  { value: 'America/Iqaluit', label: 'Eastern Time (Canada - Nunavut)' },
  { value: 'America/Whitehorse', label: 'Pacific Time (Canada - Yukon)' },
  { value: 'America/Yellowknife', label: 'Mountain Time (Canada - Northwest Territories)' },
];

interface IEditScheduleModalProps {
  visible: boolean;
  onClose: () => void;
  onUpdate: (jobDetails: any) => Promise<void>; // Adjust type as necessary
  schedule?: any; // Adjust type as necessary
  emailLists: string[];
}

const EditScheduleModal: React.FC<IEditScheduleModalProps> = ({
  visible,
  onClose,
  onUpdate,
  schedule,
  emailLists,
}) => {
  const [jobDetails, setJobDetails] = useState({
    jobTitle: '',
    jobFrequency: '',
    jobTime: '',
    jobDescription: '',
    recipientEmails: '',
    status: false, // Initialize as boolean
    timezone_txt: 'America/Chicago', // Initialize timezone
    dayOfMonth: '', // Initialize Day of Month
    dayOfWeek: '', // Initialize Day of Week
  });

  const [errors, setErrors] = useState({
    jobTitle: '',
    jobFrequency: '',
    jobTime: '',
    jobDescription: '',
    recipientEmails: '',
    status: '',
    timezone_txt: '', // Add error for timezone
  });
  const [isModalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (schedule) {
      setJobDetails({
        jobTitle: schedule.jobTitle || '',
        jobFrequency: schedule.jobFrequency || '',
        jobTime: schedule.jobTime || '',
        jobDescription: schedule.jobDescription || '',
        recipientEmails: schedule.recipientEmails || '',
        status: schedule.status === 'active',
        timezone_txt: schedule.timezone_txt || 'America/Chicago', // Load timezone from schedule if available
        dayOfMonth: schedule.jobFrequencyValue || '',
        dayOfWeek: schedule.jobFrequencyValue || '',
      });
    }
  }, [schedule]);

  const updateJobDetail = (field: string, value: string | boolean) => {
    setJobDetails(prevDetails => ({ ...prevDetails, [field]: value }));
  };

  const handleTimeChange = (time: string) => {
    setJobDetails(prevDetails => ({ ...prevDetails, jobTime: time }));
  };

  const [emailList, setEmailList] = useState<string[]>(emailLists);

  useEffect(() => {
    setEmailList(emailLists);
  }, [emailLists]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      jobTitle: '',
      jobFrequency: '',
      jobTime: '',
      jobDescription: '',
      recipientEmails: '',
      status: '',
      timezone_txt: '',
    };

    if (!jobDetails.jobTitle) {
      newErrors.jobTitle = 'Job Title is required.';
      isValid = false;
    }
    if (!jobDetails.jobFrequency) {
      newErrors.jobFrequency = 'Frequency is required.';
      isValid = false;
    }
    if (!jobDetails.jobTime) {
      newErrors.jobTime = 'Execution Time is required.';
      isValid = false;
    }
    if (!jobDetails.jobDescription) {
      newErrors.jobDescription = 'Job Description is required.';
      isValid = false;
    }
    if (!jobDetails.recipientEmails) {
      newErrors.recipientEmails = 'Recipient Email(s) are required.';
      isValid = false;
    }
    if (!jobDetails.timezone_txt) {
      newErrors.timezone_txt = 'Timezone is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleScheduleJobSubmit = async () => {
    if (validateForm()) {
      await onUpdate(jobDetails);
      onClose();
    }
  };

  const handleSaveEmails = (emails: string[]) => {
    const emailString = emails.filter(email => email.trim() !== '').join(', ');
    setJobDetails(prev => ({
      ...prev,
      recipientEmails: emailString,
    }));
  };

  const handleBlur = () => {
    const emails = jobDetails.recipientEmails
      .split(',')
      .map(email => email.trim())
      .filter(email => email && isValidEmail(email));

    const updatedEmailList = [...emailLists];

    emails.forEach(email => {
      if (!updatedEmailList.includes(email)) {
        updatedEmailList.push(email);
      }
    });

    setEmailList(updatedEmailList);

    updateJobDetail('recipientEmails', emails.join(', '));
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  };

  return (
    <Modal
      title="Edit Scheduled Report"
      visible={visible}
      onCancel={onClose}
      footer={null}
      
    >
      <form className="job-schedule-form" style={{ padding: '5px' }}>
        <label className="from-label">Scheduled Report Title</label>
        <input
          type="text"
          placeholder="Enter Title"
          value={jobDetails.jobTitle}
          onChange={e => updateJobDetail('jobTitle', e.target.value)}
          required
        />
        {errors.jobTitle && <span className="error">{errors.jobTitle}</span>}

        <div className="button-group-label">
          <div className="left-input">
            <label className="from-label">Report Frequency</label>
            <select
              value={jobDetails.jobFrequency}
              onChange={e => updateJobDetail('jobFrequency', e.target.value)}
              required
            >
              <option value="" disabled>
                Select Frequency
              </option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
            {errors.jobFrequency && <span className="error">{errors.jobFrequency}</span>}
          </div>
          <div className="left-input">
            {jobDetails.jobFrequency === 'monthly' && (
              <>
                <label className="from-label">Select Day of Month</label>
                <select
                  value={jobDetails.dayOfMonth}
                  onChange={e => updateJobDetail('dayOfMonth', e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Date
                  </option>
                  {[...Array(31)].map((_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </>
            )}

            {jobDetails.jobFrequency === 'weekly' && (
              <>
                <label className="from-label">Select Day of Week</label>
                <select
                  value={jobDetails.dayOfWeek}
                  onChange={e => updateJobDetail('dayOfWeek', e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Select Day
                  </option>
                  {[
                    'Sunday',
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                  ].map(day => (
                    <option key={day} value={day.toLowerCase()}>
                      {day}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </div>

        <div className="button-group-label">
          <div className="left-input">
            <label className="from-label">Timezone</label>
            <select
              value={jobDetails.timezone_txt}
              onChange={e => updateJobDetail('timezone_txt', e.target.value)}
            >
              {timezones.map(tz => (
                <option key={tz.value} value={tz.value}>
                  {tz.label}
                </option>
              ))}
            </select>
            {errors.timezone_txt && <span className="error">{errors.timezone_txt}</span>}
          </div>
          <div className="right-input">
            {' '}
            <label className="from-label">Report Execution Time</label>
            <TimeDropdown onTimeChange={handleTimeChange} initialTime={jobDetails.jobTime} />
            {errors.jobTime && <span className="error">{errors.jobTime}</span>}
          </div>
        </div>

        <label className="from-label">Description</label>
        <textarea
          placeholder="Enter Description"
          value={jobDetails.jobDescription}
          onChange={e => updateJobDetail('jobDescription', e.target.value)}
          required
        />
        {errors.jobDescription && <span className="error">{errors.jobDescription}</span>}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '-6px',
          }}
        >
          <label className="from-label">Recipient Email(s)</label>

          <a href="#" onClick={() => setModalVisible(true)}>
            Select Email(s)
          </a>
        </div>
        <textarea
          placeholder="Recipient Email(s)"
          value={jobDetails.recipientEmails}
          onChange={e => updateJobDetail('recipientEmails', e.target.value)}
          onBlur={handleBlur}
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '1rem',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
          required
        />
        <span className="txt_comma">Add multiple email addresses separated by comma (,)</span>
        {errors.recipientEmails && <span className="error">{errors.recipientEmails}</span>}

        <label
          className="status-switch"
          style={{ display: 'flex', alignItems: 'center', padding: '5px' }}
        >
          <b style={{ color: '#464646' }}>Status:&nbsp;</b>
          <span
            style={{
              fontSize: '1.1rem',
              marginRight: '8px',
              color: jobDetails.status ? 'green' : 'red',
            }}
          >
            {jobDetails.status ? 'Active' : 'Inactive'}
          </span>
          <Switch
            checked={jobDetails.status}
            onChange={checked => updateJobDetail('status', checked)}
            className="status-switch"
          />
        </label>

        <div className="modal-actions">
          <div className="button-group-label">
            <button type="button" onClick={handleScheduleJobSubmit} className="submit-button">
              Submit
            </button>
            <button type="button" onClick={() => onClose()} className="close-button">
              Cancel
            </button>
          </div>
        </div>
      </form>
      <EmailModal
        visible={isModalVisible}
        emailList={emailList}
        selectedEmails={jobDetails.recipientEmails.split(',').map(email => email.trim())}
        onClose={() => setModalVisible(false)}
        onSave={handleSaveEmails}
      />
    </Modal>
  );
};

export default EditScheduleModal;
