import React, { useEffect, useState } from 'react';

interface TimeDropdownProps {
  onTimeChange: (time: string) => void;
  initialTime?: string; // Add initialTime prop
}

const TimeDropdown: React.FC<TimeDropdownProps> = ({ onTimeChange, initialTime }) => {
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>('');

  useEffect(() => {
    const generateTimeOptions = () => {
      const options: string[] = [];
      // Starting from 12 AM (0:00) to 11 PM (23:00)
      for (let hour = 0; hour < 24; hour++) {
        const time = new Date(0, 0, 0, hour).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }).toUpperCase();
        options.push(time);
      }
      setTimeOptions(options);
    };

    generateTimeOptions();
  }, []);

  // Effect to set the initial selected time
  useEffect(() => {
    if (initialTime) {
      setSelectedTime(initialTime);
    }
  }, [initialTime]);

  const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedTime(selectedValue);
    onTimeChange(selectedValue);
  };

  return (
    <div>
      <select id="timeDropdown" onChange={handleTimeChange} value={selectedTime}>
        <option value="">Select time</option>
        {timeOptions.map((time, index) => (
          <option key={index} value={time}>
            {time}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeDropdown;
