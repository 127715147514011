//TODO: add type
export const englishDictionary: any = {
  sidemenu: {
    avatar: {
      hello: 'Hello',
    },
    systemAdmin: {
      menuTitle: 'Menu',
      dashboard: 'Dashboard',
      invite: 'Invite',
      safetyPrograms: 'Safety Programs',
      reports: 'Reports',
      account: 'Account',
      submissions: 'Submissions',
      subMenu: {
        inviteTeam: 'Invite Users',
        inviteAdministrator: 'Invite Administrator',
        submissionsReports: 'Submissions',
        correctiveActionsReports: 'Corrective Actions',
        hazards: 'Hazards',
      },
      correctiveActions: 'Corrective Actions',
      settings: 'Settings',
    },
    safetyProfessional: {
      safetyPrograms: 'Safety Programs',
      reports: 'Reports',
      powerBIReport: 'Power BI Reports',
      subMenu: {
        submissionsReports: 'Submissions',
        ptpSetup: 'Setup',
        correctiveActionsReports: 'Corrective Actions',
      },
      projects: 'Projects',
      account: 'Account',
      submissions: 'Submissions',
      inviteTeam: 'Invite Users',
      correctiveActions: 'Corrective Actions',
      preTaskPlans: 'Pre Task Plans',
      scheduleJobs: 'Scheduled Reports'

    },
    dashboard: {
      sectionTitle: 'Dashboard',
      customization: 'Dashboard Customization',
      reports: 'Reports',
    },
    program: {
      sectionTitle: 'Program',
      appBuilder: 'App Builder',
      broadcast: 'Broadcast',
      templates: 'Templates',
      workflow: 'Workflow',
    },
    teamManagement: {
      sectionTitle: 'Team Management',
      users: 'Users',
      groups: 'Groups',
    },
    more: {
      sectionTitle: 'More',
      help: 'Help',
    },
  },
  wizard: {
    titles: {
      companyProfile: "Let's set up your company profile!",
      safetyPrograms: "Let's add Safety Programs!",
      project: "Let's create some projects!",
      jobTitles: "Let's create some job titles!",
      team: "Let's add your team!",
    },
    headings: {
      profile: 'Profile',
      safetyProgram: 'Safety Program',
      team: 'Team',
      finish: 'Finish',
    },
    profile: {
      companyLogo: 'Company Logo',
    },
    safetyProgram: {
      forms: 'Forms',
      question: 'Questions',
    },
    team: {
      messageClick: 'Click',
      messageCSV: 'and Download the CSV file to add your Team members.  You will need to fill out the Team members information and upload it using our Drag and Drop tool.',
      upload: 'Drag and Drop to upload spreadsheet or .CSV file',
      admin: {
        messageClick: 'Click',
        messageCSV: 'and Download the CSV file.',
        messageCSV2: 'Enter Team members information on CSV file.',
        messageCSV3: 'Make sure to enter the ROLE as : \'Administrator\' or \'User\'.',
        messageCSV4: 'Save.',
        messageCSV5: 'Use Drag/Drop to Upload .CSV file option.',
        upload: 'Drag and Drop to upload spreadsheet or .CSV file',
      }
    },
    finish: {
      sectionTitle: 'Congratulations!',
      message: 'Your company profile, projects, and team have been successfully created!',
    },
    projects: {
      listNumber: 'Project number',
      listName: 'Project name',
      listAddres: 'Project address',
    },
  },
  registration: {
    titleHello:
      'Hello, Velocity Vue is inviting you to activate your account for the Safety Assist platform!',
    message: 'Please fill up the form to activate your account.',
    formTitle: 'Your Info',
    emergencyTitle: 'Emergency Contact Info',
    createYourAccount: 'Password Registration',
  },
  login: {
    restorePasswordTitle: 'Restore password',
    restorePasswordMessage: 'Please create a new password',
  },
  buttons: {
    browse: 'Browse',
    previous: 'Previous',
    continue: 'Continue',
    cancel: 'Cancel',
    done: 'Done',
    submit: 'Submit',
    emailMe: 'Email me a link',
    register: 'Register',
    logout: 'Logout',
    login: 'Sign in',
    loginManually: 'Login Manually',
    restorePassword: 'Restore password',
    ok: 'Ok',
    invite: 'Invite',
    back: 'Back',
    next: 'Next',
    here: 'here',
  },
  inputs: {
    add: 'Add',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    emailAddress: 'Email Address',
    address: 'Address',
    contactNumber: 'Phone Number',
    dayOfBirth: 'Birthday',
    optional: 'Optional',
    contactName: 'Contact Name',
    contactState: 'State',
    country: 'Country',
    city: 'City',
    contactPhone: 'Contact Phone',
    contactEmail: 'Contact Email',
    powerBIKey: 'Power BI Key',
    password: 'Password',
    tier: 'Tier',
    confirmPassword: 'Confirm Password',
    newPassword: 'New Password',
    company: 'Company',
    companyName: 'Company Name',
    companyAddress: 'Company Address',
    contractorCompany: 'Company Name',
    inviteType: 'Invite Type',
    invitePassword: 'Invite Password',
    noLicenses: 'Number of Licenses',
    role: 'Role',
    apikey: 'Api Key',
    powerBiKey: 'Power BI Key',
    rememberPassword: 'Remember password',
    forgotPassword: 'Forgot Password?',
    wrongPassword: 'The entered email or password are incorrect.',
    industry: 'Industry',
    state: 'State',
    jobTitle: 'Job Title',
    package: 'License Type',
    reportsTo: 'Reports To',
    contractor: 'Company Name',
    createProject: 'Create Project',
    requiredName: 'Please, enter your name',
    requiredLastName: 'Please, enter your last name',
    requiredEmail: 'Please, enter a valid email',
    requiredEmailAddress: 'Please, enter a valid email address',
    requiredAddress: 'Please, enter your address',
    requiredContactNumber: 'Please, enter your contact number',
    requiredContactName: 'Please, enter a contact name.',
    requiredContactPhone: 'Please, enter a contact number',
    requiredPassword: 'Please, enter a password',
    requiredConfirmPassword: 'Confirm Password',
    requiredCompany: 'Please enter a company',
    requiredCompanyName: "Please enter company's name",
    requiredCompanyAddress: "Please enter company's address",
    requiredRole: 'Please enter a role',
    requiredIndustry: 'Please, select an industry',
    requiredState: 'Please, select a state',
    requiredJobTitle: 'Please, select a job title',
    invalidImageGreater: 'Image size must not be greater than',
    invalidImageLower: 'Image size must not be lower than',
    invalidImageSize: 'Image must not be larger than',
    invalidImage: 'Image should not be larger than 2MB and 720px x 1280px',
    weakPassword: 'Password is too weak.',
    invalidPassword:
      'Password must contain at least 6 characters 1 uppercase, 1 number, and 1 special character',
    unmatchingPassword: 'The entered passwords do not match.',
    requiredNoLicences: "Please enter No. of Licences",
    requiredLicenceType: "Please select Licence Type",
    requiredReportsTo: "Please select Reports To",

  },
  months: {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Agust',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
  help: {
    phone: "Don't worry, if you need us just call 1-800-XXX-XXX",
    notFoundTitle: "Oops! Looks like this website doesn't exist.",
    notFoundSubtitle: 'Check your web address and try again.',
    contactSupport: 'Contact Support',
  },
  signin: {
    welcome: 'Login',
    instruction: 'Welcome back! Please login to your account.',
  },
  password: {
    pleaseCreate: 'Please, create the password for your account',
  },
  congratulations: {
    congratulations: 'Congratulations!',
    accountActivated: 'Your account has been successfully activated and password created!',
    ableToLogin: 'You are able to login into your',
    setCompanyProfile: "and set your company's profile.",
    title: 'Congratulations! You are ready to go.',
    emailReceivePrefix: 'You will receive an email at',
    emailReceiveSuffix: 'with a link to create your password',
    yourInbox: 'your inbox',
    dashboardPreMessage: 'Now you can go to your',
    dashboardPostMessage: "and create your Company's profile",
    orMessage: 'Or',
    mobileMessage: 'your mobile application',
    application: 'application',
    download: 'Download',
    dashboard: 'dashboard',
    loginToDashboard: 'Login to dashboard',
    thanksSignUp: 'Thanks for Signing Up',
  },
  modal: {
    error: {
      ups: 'Error!',
    },
    add: {
      supervisors: {
        title: 'Add new supervisor',
        successTitle: 'Success!',
        successMessage: 'Your supervisor was successfully added.',
      },
      company: {
        title: 'Create new company',
        successTitle: 'Success!',
        successMessage: 'Your company was successfully added.',
        errorMessage: 'There was an error when creating your company.',
      },
    },
    invite: {
      title: 'Invitation sent!',
      message: 'The invitation was succesfully sent to',
      error: 'There has been an error when sending the invite',
    },
    register: {
      title: 'Congratulations!',
      message: 'Your account has been successfully activated and your password created',
      error: 'There has been an error in the register.',
    },
    restorePassword: {
      title: 'Email Sent!',
      message: 'You will receive an email with a link to restore your password.',
      error: 'There has been an error when sending restore email.',
    },
    passwordRestored: {
      title: 'Password has been updated!',
      message: 'You can use your new credentials now.',
      error: 'There has been an error when updating your password.',
    },
    setupCompany: {
      message: "You already have your account, now let's create your company profile!",
    },
  },
  dashboard: {
    admin: {
      invite: {
        formTitle: 'Invite Administrator',
        formMessage: 'Create a Safety Professional or Administrator Profile',
      },
    },
    user: {
      invite: {
        formTitle: 'Invite Users',
        formMessage: 'Create a Safety User Profile',
      },
    },
  },
};
