import React from 'react';
import { Spin } from 'antd';

const CenteredLoader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)', // Center the loader
        zIndex: 999, // Ensure it appears above other elements
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default CenteredLoader;
