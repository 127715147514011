import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';

export const _getProfessionalErrors = (state: IAppState) => state.professional.errorMessages;

export const getProfessionalErrors = createSelector([_getProfessionalErrors], errors => errors);

export const _getStates = (state: IAppState) => state.professional.states;

export const getStates = createSelector([_getStates], states => states);

export const _getIndustries = (state: IAppState) => state.professional.industries;

export const getIndustries = createSelector([_getIndustries], industries => industries);

export const _getPlans = (state: IAppState) => state.professional.plans;

export const getPlans = createSelector([_getPlans], plans => plans);

export const _getPlanForms = (state: IAppState) => state.professional.forms;

export const getPlanForms = createSelector([_getPlanForms], forms => getList(forms));

export const _getValidSteps = (state: IAppState) => state.professional.programs.meta.validSteps;

export const getValidSteps = createSelector([_getValidSteps], steps => steps);

export const _getPrograms = (state: IAppState) => state.professional.programs;

export const getPrograms = createSelector([_getPrograms], forms => forms);

export const _getCurrentSubstep = (state: IAppState) =>
  state.professional.programs.meta.currentSubstep;

export const getCurrentSubstep = createSelector([_getCurrentSubstep], subStep => subStep);

export const _getProgramsForms = (state: IAppState) => state.professional.programs.forms;

export const getProgramsForms = createSelector([_getProgramsForms], form => form);

export const _getFormQuestion = (state: IAppState) => state.professional.questions;

export const getFormQuestion = createSelector([_getFormQuestion], question => {
  return getList(question);
});

export const _getSelectedFormQuestion = (state: IAppState) => state.professional.question;

export const getSelectedFormQuestion = createSelector([_getSelectedFormQuestion], question => {
  return getList(question || null);
});

export const _getJobTitles = (state: IAppState) => state.professional.jobTitles;

export const getJobTitles = createSelector([_getJobTitles], jobTitles => jobTitles);

export const _getWizardPermit = (state: IAppState) => state.professional.hasWizard;

export const getWizardPermit = createSelector([_getWizardPermit], hasWizard => hasWizard);

export const _getFormMeta = (state: IAppState) => state.professional.programs.meta;

export const getFormMeta = createSelector([_getFormMeta], meta => meta);

export const _getProfessionalIsLoading = (state: IAppState) => state.professional.isLoading;

export const getProfessionalIsLoading = createSelector(
  [_getProfessionalIsLoading],
  isLoading => isLoading,
);

export const _getErrorMessages = (state: IAppState) => state.professional.errorMessages;

export const getErrorMessages = createSelector([_getErrorMessages], errMessage => errMessage);

export const _getProjects = (state: IAppState) => state.professional.projects;

export const getProjects = createSelector([_getProjects], project => project);

export const _getSchedule = (state: IAppState) => state.professional.schedule;

export const getSchedule = createSelector([_getSchedule], schedule => schedule);

export const _getProgramsProjects = (state: IAppState) => state.professional.programs.projects;

export const getProgramsProjects = createSelector([_getProgramsProjects], programProject => {
  const getOrderer = getList(programProject).sort(function(a: any, b: any) {
    return parseInt(a.key) - parseInt(b.key);
  });
  return getOrderer;
});

export const _getHasSentInvite = (state: IAppState) => state.professional.team.hasSentInvite;

export const getHasSentInvite = createSelector([_getHasSentInvite], invite => invite);

export const _getCachedSupervisorID = (state: IAppState) =>
  state.professional.team.cachedSupervisorID;

export const getCachedSupervisorID = createSelector(
  [_getCachedSupervisorID],
  supervisorID => supervisorID,
);

export const _getProjectCachedCounter = (state: IAppState) =>
  state.professional.programs.projectCachedCounter;

export const getProjectCachedCounter = createSelector(
  [_getProjectCachedCounter],
  project => project,
);

export const _getProgramsJobTitles = (state: IAppState) => state.professional.programs.jobTitles;

export const getProgramsJobTitles = createSelector([_getProgramsJobTitles], jobTitle => jobTitle);

export const _getTeamCachedCounter = (state: IAppState) =>
  state.professional.programs.teamCachedCounter;

export const getTeamCachedCounter = createSelector([_getTeamCachedCounter], team => team);

export const _getProgramsAccountID = (state: IAppState) => state.professional.programs.accountID;

export const getProgramsAccountID = createSelector([_getProgramsAccountID], accountID => accountID);
