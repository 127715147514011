import {
    fetchAccountsScheduleAction,
    updateScheduleAction,
    removeScheduleAction,
    removeScheduleRowAction,
  } from './schedule.actions';
  import { IProfessionalState } from '@app/back-office/professional.reducer';
  import { AnyAction } from 'redux';
  import { combineActions } from 'redux-actions';
  
  export const scheduleReducer = {
    // Handle success for fetching schedules
    [fetchAccountsScheduleAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => ({
      ...state,
      isLoading: false,
      schedule: payload.schedules, // Ensure the property name matches the action payload
    }),
  
    // Fulfill action to reset loading state if necessary
    [fetchAccountsScheduleAction.FULFILL]: (state: IProfessionalState) => ({
      ...state,
      isLoading: false,
    }),
  
    // Handle success for removing a schedule row
    [removeScheduleRowAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
      const { key } = payload;
      const updatedSchedule = { ...state.schedule };
      delete updatedSchedule[key]; // Remove the schedule by key
  
      return {
        ...state,
        schedule: updatedSchedule,
      };
    },
  
    // Handle success for updating a schedule
    [updateScheduleAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
      const updatedSchedule = {
        ...state.schedule,
        [payload.schedule.scheduleID]: payload.schedule, // Use scheduleID instead of key for consistency
      };
  
      return {
        ...state,
        schedule: updatedSchedule,
      };
    },
  
    // Handle success for removing a schedule item
    [removeScheduleAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
      const { scheduleID } = payload; // Change from projectID to scheduleID for consistency
      const updatedSchedules = { ...state.schedule };
      delete updatedSchedules[scheduleID]; // Remove the schedule by scheduleID
  
      return {
        ...state,
        schedule: updatedSchedules,
      };
    },
  
    // Handle failure for various actions
    [combineActions(
      fetchAccountsScheduleAction.FAILURE,
      updateScheduleAction.FAILURE,
      removeScheduleAction.FAILURE,
    ) as any]: (state: IProfessionalState, { payload }: AnyAction) => ({
      ...state,
      isLoading: false,
      errorMessages: {
        ...state.errorMessages,
        [payload.error.code]: payload.error,
      },
    }),
  };
  