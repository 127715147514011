import { takeLatest, call, put } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { ScheduleType } from '@shared/types/backend.types'; // Ensure ScheduleType is correctly defined
import { fetchService, updateService, deleteService } from '@rdx/services.saga';
import {
  fetchAccountsScheduleAction,
  updateScheduleAction,
  removeScheduleAction,
  removeScheduleRowAction,
} from './schedule.actions';
import { arrayToObject } from '@shared/helpers/state-caster';

function* fetchScheduleRequest(action: AnyAction) {
  try {
    const endPoint = fetchAccountsScheduleAction.getEndpoint({
      id: action.payload.accountID,
      userID: action.payload.userID,
    });

    console.log('userID',action.payload.userID);
    
    // Fetch data from the service
    const data = yield call(fetchService, endPoint);
    console.log('Fetched schedule data:', data);

    // Ensure the data is an array or handle the structure appropriately
    const schedules = Array.isArray(data) ? data : [];
    const schedulesObject = arrayToObject(schedules, 'schedules');

    // Dispatch success action with the schedules object
    yield put(fetchAccountsScheduleAction.success({ schedules: schedules }));
  } catch (error) {
    // Handle the error
    yield put(
      fetchAccountsScheduleAction.failure({
        error: {
          code: 'backOfficeFetchSchedules',
          title: 'Failed to retrieve schedules',
          message: error.message || 'There has been an error while retrieving schedules.',
        },
      })
    );
  }
}

function* updateScheduleRequest(action: AnyAction) {
  try {
    const endpoint = updateScheduleAction.getEndpoint({
      accountID: action.payload.accountID,
    });

    // Call the update service
    yield call(updateService, endpoint, action.payload.schedule);
    
    // Dispatch success action after successful update
    yield put(updateScheduleAction.success({ schedule: action.payload.schedule }));
  } catch (error) {
    // Handle update error
    yield put(
      updateScheduleAction.failure({
        error: {
          code: 'backOfficeUpdateSchedule',
          title: 'Failed to update schedule',
          message: error.message || 'There has been an error while updating the schedule.',
        },
      })
    );
  }
}

function* removeScheduleRequest(action: AnyAction) {
  try {
    const { accountID, scheduleID } = action.payload;
    const endpoint = removeScheduleAction.getEndpoint({ accountID, scheduleID });
    const fetchSchedulesEndPoint = fetchAccountsScheduleAction.getEndpoint({ accountID });

    // Call the delete service
    yield call(deleteService, endpoint);

    // Fetch updated schedules after removal
    const schedules: ScheduleType[] = yield call(fetchService, fetchSchedulesEndPoint);
    
    const schedulesObject = arrayToObject(schedules.map(({ scheduleID, ...rest }) => ({
      ...rest,
      key: scheduleID,
      scheduleID,
    })), 'scheduleID');

    // Dispatch success actions
    yield put(fetchAccountsScheduleAction.success({ schedules: schedulesObject }));
    yield put(removeScheduleAction.success({ scheduleID }));
  } catch (error) {
    // Handle removal error
    yield put(
      removeScheduleAction.failure({
        error: {
          code: 'backOfficeRemoveSchedule',
          title: 'Failed to remove schedule',
          message: error.message || 'There has been an error while removing the schedule.',
        },
      })
    );
  }
}

function* removeScheduleRowTrigger(action: AnyAction) {
  yield put(removeScheduleAction.fulfill({ key: action.payload.key }));
}

export function* scheduleSaga() {
  yield takeLatest(fetchAccountsScheduleAction.REQUEST, fetchScheduleRequest);
  yield takeLatest(updateScheduleAction.REQUEST, updateScheduleRequest);
  yield takeLatest(removeScheduleAction.REQUEST, removeScheduleRequest);
  yield takeLatest(removeScheduleRowAction.TRIGGER, removeScheduleRowTrigger);
}
