import React, { Fragment } from 'react';

import { Switch, Redirect } from 'react-router-dom';

import { Route } from 'react-router';
import { PrivateRouteHOC } from '@routes/private-route.hoc';

import { NavigationMenuContainer } from '@layout/navigation-menu/navigation-menu.container';
import { ProfessionalProgramsContainer } from './programs/state/programs.container';
import { TeamLockedContainer } from './team/state/team.container';
import { ProjectsLockedContainer } from './projects/state/projects.container';
import { PlansLockedContainer } from './safety-programs/state/safety-programs.container';
import SubmissionsReportsContainer from './reports/submissions/state/sub.reports.container';
import { ProjectFormsContainer } from './submissions/state/submissions.container';
import { ScheduleLockedContainer } from './schedule-job/state/schedule.container';
import { BackOfficeInviteTeamContainer } from './invite-team/state/invite-team.container';

import { ROLE } from '@constants/roles';
import CorrectiveActionsRoutes from './corrective-actions/state/corrective-actions.routes';
import CAReportsContainer from './reports/corrective-actions/state/ca.reports.container';
import ProxyUsersContainer from '@components/proxy-users/proxy-users.container';
import { PreTaskPlansRoutes } from '@shared/pre-task-plan/state/pre-task-plan.routes';
import { PreTaskPlanContainer } from '@shared/pre-task-plan/state/pre-task-plan.container';
import PtpTaskContainer from '@shared/pre-task-plan/ptp-task/state/ptp-task.container';
import PtpSubmissionsContainer from '@shared/pre-task-plan/reports/state/ptp-submissions.container';

export const ProfessionalRoutes = () => (
  <Fragment>
    <NavigationMenuContainer>
      <Switch>
        <PrivateRouteHOC
          path="/back-office/reports/submissions"
          component={SubmissionsReportsContainer}
          whitelistedRoles={[ROLE[2]]}
        />

        <PrivateRouteHOC
          path="/back-office/reports/corrective-actions"
          component={CAReportsContainer}
          whitelistedRoles={[ROLE[2]]}
        />
        <PrivateRouteHOC
          path="/back-office/programs"
          component={ProfessionalProgramsContainer}
          whitelistedRoles={[ROLE[2]]}
        />
        <PrivateRouteHOC
          path="/back-office/account"
          component={TeamLockedContainer}
          whitelistedRoles={[ROLE[2]]}
        />

        <PrivateRouteHOC
          path="/back-office/user/:userID/proxy-users"
          component={ProxyUsersContainer}
          whitelistedRoles={[ROLE[2]]}
        />

        <PrivateRouteHOC
          path="/back-office/projects"
          component={ProjectsLockedContainer}
          whitelistedRoles={[ROLE[2]]}
        />

        <PrivateRouteHOC
          path="/back-office/schedule-jobs"
          component={ScheduleLockedContainer}
          
        />
        <PrivateRouteHOC
          path="/back-office/plans"
          component={PlansLockedContainer}
          whitelistedRoles={[ROLE[2]]}
        />
        <PrivateRouteHOC
          path="/back-office/submissions"
          component={ProjectFormsContainer}
          whitelistedRoles={[ROLE[2]]}
        />
        <PrivateRouteHOC
          path="/back-office/invite-team"
          component={BackOfficeInviteTeamContainer}
          whitelistedRoles={[ROLE[2]]}
        />


        {/* 
        <PrivateRouteHOC
          path="/back-office/pre-task-plans/task/:ptpId"
          component={PtpTaskContainer}
          whitelistedRoles={[ROLE[2]]}
        /> */}

        <PrivateRouteHOC
          path="/back-office/pre-task-plans"
          component={PtpSubmissionsContainer}
          whitelistedRoles={[ROLE[2]]}
        />

        {/* <PrivateRouteHOC
          path="/back-office/pre-task-plans"
          component={PreTaskPlanContainer}
          whitelistedRoles={[ROLE[2]]}
        /> */}

        {/* <PreTaskPlansRoutes /> */}

        {/* Corrective Actions Routes
         * Route - /back-office/corrective-actions */}
        <CorrectiveActionsRoutes />

        <Route path="/back-office" render={() => <Redirect to="/back-office/account" />} />
      </Switch>
    </NavigationMenuContainer>
  </Fragment>
);
