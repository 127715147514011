import React from "react";
import "./Loading.css";
import loadingGif from "./loading.gif"; // Update the path to your GIF file

const LoadingIndicator: React.FC = () => {
  return (
    <div className="loading-indicator">
      <img src={loadingGif} alt="Loading..." className="loading-gif" />
    </div>
  );
};

export default LoadingIndicator;
